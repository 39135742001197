function addSlashes(href) {
  href = href.indexOf("/") !== 0 ? `/${href}` : href
  href = href.endsWith("/") ? href : `${href}/`

  return href
}

function getAlternateLanguageUrls(pageContext, themeOptions, path) {
  const is404 = !pageContext?.id
  const thisLanguage = pageContext?.language || themeOptions.primaryLanguage
  const locales = themeOptions?.language
    ? Object.keys(themeOptions?.language)
    : []
  const currentPath = path
    ? path
    : pageContext?.pageUrl?.replace(pageContext?.url, "")
  const seoAlternateLanguages = pageContext?.seo?.alternateLanguages || []
  const alternateLanguages = []

  locales.forEach((locale) => {
    const thisSeoAlternate = seoAlternateLanguages.find(
      (item) => item?.language === locale
    )
    const active = locale === thisLanguage
    const href =
      active && !is404
        ? currentPath
        : thisSeoAlternate?.slug
        ? thisSeoAlternate.slug
        : themeOptions.language[locale]

    alternateLanguages.push({
      language: locale,
      active,
      href: addSlashes(href),
    })
  })

  return alternateLanguages
}

function getLayoutData(pageContext, themeOptions, path) {
  return {
    seo: pageContext?.seo || null,
    url: pageContext?.url || null,
    pageUrl: pageContext?.pageUrl || null,
    headerItems: pageContext?.headerItems || null,
    alternateLanguages: getAlternateLanguageUrls(
      pageContext,
      themeOptions,
      path
    ),
  }
}

module.exports = {
  getLayoutData,
}
