module.exports = [{
      plugin: require('../../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1280,"quality":90,"withWebp":true,"srcSetBreakpoints":[576],"linkImagesToOriginal":false,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1280,"quality":90,"withWebp":true,"srcSetBreakpoints":[576],"linkImagesToOriginal":false,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../../../packages/soluble-source/gatsby-browser.js'),
      options: {"plugins":[],"primaryLanguage":"es","language":{"es":"","en":"en","fr":"fr","de":"de"},"pathsByLanguage":{"Blog":{"es":"blog","en":"blog","fr":"blog","de":"blog"},"BlogPaginationPage":{"en":"blog/page","es":"blog/pagina","fr":"blog/page","de":"blog/seite"},"Tag":{"es":"blog","en":"blog","fr":"blog","de":"blog"},"Whitepaper":{"en":"resources/whitepapers","es":"recursos/whitepapers","fr":"ressources/livre-blanc","de":"ressourcen/whitepapers"},"Event":{"en":"resources/events","es":"recursos/eventos","fr":"ressources/evenements","de":"ressourcen/events"},"SuccessStory":{"en":"resources/case-study","es":"recursos/casos-de-exito","fr":"ressources/reussites","de":"ressourcen/fallstudie"}},"datocms":{"previewMode":false,"modelPages":["Page","Blog","Tag","Whitepaper","SuccessStory"],"modelsPagination":{"Blog":{"pagination":{"firstPageItems":9,"perPage":10},"templateKey":"post-page","sectionKey":"BlogSection","itemsKey":"posts"}},"redirects":true}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bdeo - Bdeo.io","short_name":"Bdeo","start_url":"/","background_color":"#050505","theme_color":"#050505","display":"minimal-ui","icon":"src/img/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2daac4211dfd1644d61a1e0ddf46f251"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":0.2,"once":true,"rootMargin":"0% 100%"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NHTBFQG","defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-always-route-change","includeInDevelopment":false,"enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
