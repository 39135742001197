export const onClientEntry = () => {
  if (window.location.pathname === "/") {
    if (!window.localStorage.lang) {
      const userLang =
        window.navigator.language || window.navigator.userLanguage
      if (userLang.indexOf("fr") !== -1) {
        window.location.href = "/fr/"
      } else if (userLang.indexOf("de") !== -1) {
        window.location.href = "/de/"
      } else if (
        userLang.indexOf("es") === -1 &&
        userLang !== "eu" &&
        userLang !== "ca" &&
        userLang !== "gl"
      ) {
        window.location.href = "/en/"
      }
    } else if (window.localStorage.lang === "en") {
      window.location.href = "/en/"
    } else if (window.localStorage.lang === "fr") {
      window.location.href = "/fr/"
    } else if (window.localStorage.lang === "de") {
      window.location.href = "/de/"
    }
  }
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (prevLocation) {
    setTimeout(() => {
      let data = window["dataLayer"]
      if (data) {
        data.push({ event: `gatsby-route-change` })
      }
    }, 50)
  }
}
